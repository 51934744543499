$bg-color: rgb(240, 236, 247);
$dot-color: rgb(3, 1, 8);
$dot-size: 1px;
$dot-space: 22px;

#root {
  display: flex;
  align-items: center;
  height: 95vh;
}

body {
  margin: 0;
  background: linear-gradient(
        90deg,
        $bg-color ($dot-space - $dot-size),
        transparent 1%
      )
      center,
    linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
    $dot-color;
  background-size: $dot-space $dot-space;
}
