@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Vollkorn+SC:wght@400;600;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Vollkorn SC", serif;
  }

  h1 {
    font-weight: 600;
    padding-bottom: 8px;
    position: relative;
  }

  h1::before {
    content: "";
    position: absolute;
    bottom: 3px;
    width: 124px;
    height: 14px;
    transform: skew(-12deg) translateX(-5%);
    background: rgba(238, 111, 87, 0.5);
    z-index: -1;
  }
}
